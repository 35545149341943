import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import gsap from "gsap";

/**
 * Base
 */

// Elements
const canvas = document.querySelector("canvas.webgl");
const cursor = document.querySelector(".cursor");
const cursor2 = document.querySelector(".cursor2");
const loaderBg = document.querySelector(".loader-bg");
const logo = document.querySelector(".logo");

const room1 = document.querySelectorAll(".room1");
const room2 = document.querySelectorAll(".room2");
const room3 = document.querySelectorAll(".room3");
const title = document.querySelector(".title");

let isCursorActive = false;

const positions = [
  {
    desktop: [new THREE.Vector3(-9, 9, -9), new THREE.Vector3(-0.5, 2, -1)],
    mobile: [new THREE.Vector3(-22, 15, -22), new THREE.Vector3(0, 0, 0)],
  },
  {
    desktop: [new THREE.Vector3(-9, 12, -1), new THREE.Vector3(-3, 8, 6)],
    mobile: [new THREE.Vector3(-20, 16, -20), new THREE.Vector3(3, 0, 12)],
  },
  {
    desktop: [new THREE.Vector3(-2, 13, -8), new THREE.Vector3(5, 8, -2)],
    mobile: [new THREE.Vector3(-20, 16, -20), new THREE.Vector3(12, 0, 3)],
  },
];

[...room1, ...room2, ...room3].forEach(function (item) {
  item.addEventListener("click", (e) => {
    const index = e.target.dataset.index;
    const className = e.target.classList[0];

    const allBtns = document.querySelectorAll(".room-btn");
    const thisBtn = document.querySelector(`.${className}.room-btn`);
    const active = thisBtn.classList.contains("active");

    allBtns.forEach((item) => item.classList.remove("active"));

    if (active) {
      setCameraPosition(camera, sizes, true);
      setControlPorps(controls, sizes, true);
      return;
    }

    if (thisBtn) thisBtn.classList.add("active");

    const position = isDesktop(sizes)
      ? positions[index].desktop[0]
      : positions[index].mobile[0];

    const target = isDesktop(sizes)
      ? positions[index].desktop[1]
      : positions[index].mobile[1];

    gsap.to(camera.position, {
      duration: 1,
      x: position.x,
      y: position.y,
      z: position.z,
    });

    gsap.to(controls.target, {
      duration: 1,
      x: target.x,
      y: target.y,
      z: target.z,
    });
  });
});

[...room1, ...room2, ...room3, title].forEach(function (item) {
  item.addEventListener("mouseover", () => {
    isCursorActive = true;
  });
  item.addEventListener("mouseout", () => {
    isCursorActive = false;
  });
});

// Scene
const scene = new THREE.Scene();

/**
 * LoadingManager
 */

let rotation = 90;
let timeouts = [];
let ms = 0;

const degs = Array.from({ length: 18 })
  .fill(0)
  .map((v, i) => 90 + i * 4 * 90);

const loadingManager = new THREE.LoadingManager(
  () => {
    setTimeout(() => {
      const deg = Math.min(...degs.filter((num) => num >= rotation));

      loaderBg.classList.remove("show");
      setTimeout(() => {
        loaderBg.classList.add("hide");
      }, 250);

      logo.classList.remove("loader");
      logo.classList.add("menu");
      logo.style.cssText = `transform: rotate(${deg}deg)`;

      timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    }, 1000);
  },
  (url, loaded, total) => {
    ms += 500;

    const timeout = setTimeout(() => {
      rotation += 90;
      logo.style.cssText = `transform: rotate(${rotation}deg)`;
    }, ms);

    timeouts.push(timeout);

    const progress = loaded / total;
  }
);

/**
 * Loaders
 */

// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager);

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Textures
 */

const room1Texture = textureLoader.load("assets/baked-1.jpg");
room1Texture.flipY = false;
room1Texture.colorSpace = THREE.SRGBColorSpace;

const room1StuffTexture = textureLoader.load("assets/baked-1-stuff.jpg");
room1StuffTexture.flipY = false;
room1StuffTexture.colorSpace = THREE.SRGBColorSpace;

const room2Texture = textureLoader.load("assets/baked-2.jpg");
room2Texture.flipY = false;
room2Texture.colorSpace = THREE.SRGBColorSpace;

const room3Texture = textureLoader.load("assets/baked-3.jpg");
room3Texture.flipY = false;
room3Texture.colorSpace = THREE.SRGBColorSpace;

const columnsTexture = textureLoader.load("assets/columns.jpg");
columnsTexture.flipY = false;
columnsTexture.colorSpace = THREE.SRGBColorSpace;

const floorTexture = textureLoader.load("assets/floor.jpg");
floorTexture.flipY = false;
floorTexture.colorSpace = THREE.SRGBColorSpace;

/**
 * Materials
 */

const room1Material = new THREE.MeshBasicMaterial({ map: room1Texture });
const room1StuffMaterial = new THREE.MeshBasicMaterial({
  map: room1StuffTexture,
});
const room2Material = new THREE.MeshBasicMaterial({ map: room2Texture });
const room3Material = new THREE.MeshBasicMaterial({ map: room3Texture });
const columnsMaterial = new THREE.MeshBasicMaterial({ map: columnsTexture });
const floorMaterial = new THREE.MeshBasicMaterial({ map: floorTexture });

const whiteMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
const purpleMaterial = new THREE.MeshBasicMaterial({ color: 0xc88fcf });

/**
 * Objects
 */

let khaledTextMesh = null;
let instagramMesh = null;
let instagramBgMesh = null;
let linkedInMesh = null;
let linkedInBgMesh = null;
let twitterMesh = null;
let twitterBgMesh = null;

const instagramUrl = "https://www.instagram.com/khaled.lazkani/";
const linkedInUrl = "https://www.linkedin.com/in/khaled-lazkani/";
const twitterUrl = "https://twitter.com/KhaledLazk58051/";

/**
 * Models
 */

gltfLoader.load("assets/room-1.glb", (gltf) => {
  gltf.scene.traverse((child) => {
    child.material = room1Material;
  });

  scene.add(gltf.scene);
});

gltfLoader.load("assets/room-1-stuff.glb", (gltf) => {
  gltf.scene.traverse((child) => {
    child.material = room1StuffMaterial;
  });

  khaledTextMesh = gltf.scene.children.find((x) => x.name === "Text");
  khaledTextMesh.material = purpleMaterial;

  scene.add(gltf.scene);
});

gltfLoader.load("assets/room-2.glb", (gltf) => {
  gltf.scene.traverse((child) => {
    child.material = room2Material;
  });

  const playMesh = gltf.scene.children.find((x) => x.name === "play");
  const netMesh = gltf.scene.children.find((x) => x.name === "Plane001");
  const linesMesh = gltf.scene.children.find((x) => x.name === "lines");

  playMesh.material = whiteMaterial;
  netMesh.material = whiteMaterial;
  linesMesh.material = whiteMaterial;

  scene.add(gltf.scene);
});

gltfLoader.load("assets/room-3.glb", (gltf) => {
  gltf.scene.traverse((child) => {
    child.material = room3Material;
  });

  const text1Mesh = gltf.scene.children.find((x) => x.name === "Text001");
  const text2Mesh = gltf.scene.children.find((x) => x.name === "Text002");
  const text3Mesh = gltf.scene.children.find((x) => x.name === "Text003");

  instagramMesh = gltf.scene.children.find((x) => x.name === "Curve005");
  instagramBgMesh = gltf.scene.children.find((x) => x.name === "Rounded");
  linkedInMesh = gltf.scene.children.find((x) => x.name === "Curve003");
  linkedInBgMesh = gltf.scene.children.find((x) => x.name === "Rounded001");
  twitterMesh = gltf.scene.children.find((x) => x.name === "Curve004");
  twitterBgMesh = gltf.scene.children.find((x) => x.name === "Rounded002");

  text1Mesh.material = whiteMaterial;
  text2Mesh.material = whiteMaterial;
  text3Mesh.material = whiteMaterial;

  scene.add(gltf.scene);
});

gltfLoader.load("assets/columns.glb", (gltf) => {
  gltf.scene.traverse((child) => {
    child.material = columnsMaterial;
  });

  scene.add(gltf.scene);
});

gltfLoader.load("assets/floor.glb", (gltf) => {
  gltf.scene.traverse((child) => {
    child.material = floorMaterial;
  });

  scene.add(gltf.scene);
});

/**
 * Sizes
 */

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

function isDesktop(sizes) {
  return sizes.width >= 1024;
}

function setCameraPosition(camera, sizes, animate = false) {
  if (isDesktop(sizes)) {
    if (animate) {
      gsap.to(camera.position, {
        duration: 1,
        x: -18,
        y: 16,
        z: -18,
      });
    } else {
      camera.position.set(-18, 16, -18);
    }
  } else {
    if (animate) {
      gsap.to(camera.position, {
        duration: 1,
        x: -48,
        y: 32,
        z: -48,
      });
    } else {
      camera.position.set(-48, 32, -48);
    }
  }
}

function setControlPorps(controls, sizes, animate = false) {
  if (isDesktop(sizes)) {
    if (animate) {
      gsap.to(controls.target, {
        duration: 1,
        x: 0,
        y: 5,
        z: 0,
      });
    } else {
      controls.target.set(0, 5, 0);
    }

    controls.minDistance = 8;
    controls.maxDistance = 50;
    controls.maxPolarAngle = Math.PI / 2;
    controls.rotateSpeed = 0.5;
  } else {
    if (animate) {
      gsap.to(controls.target, {
        duration: 1,
        x: 0,
        y: 0,
        z: 0,
      });
    } else {
      controls.target.set(0, 0, 0);
    }

    controls.minDistance = 20;
    controls.maxDistance = 110;
    controls.maxPolarAngle = Math.PI / 2.2;
    controls.rotateSpeed = 0.75;
  }
}

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  setCameraPosition(camera, sizes);
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update controls
  setControlPorps(controls, sizes);

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Raycaster
 */

const raycaster = new THREE.Raycaster();
let currentIntersect = null;

/**
 * Mouse
 */

const client = new THREE.Vector2();
const mouse = new THREE.Vector2();

window.addEventListener("mousemove", (event) => {
  client.x = event.clientX;
  client.y = event.clientY;

  mouse.x = (event.clientX / sizes.width) * 2 - 1;
  mouse.y = -(event.clientY / sizes.height) * 2 + 1;
});

// window.addEventListener("touchstart", (event) => {
//   client.x = event.touches[0].clientX;
//   client.y = event.touches[0].clientY;

//   mouse.x = (client.x / sizes.width) * 2 - 1;
//   mouse.y = -(client.y / sizes.height) * 2 + 1;
// });

window.addEventListener("click", () => {
  if (currentIntersect) {
    switch (currentIntersect.object) {
      case instagramMesh:
      case instagramBgMesh:
        window.open(instagramUrl, "_blank").focus();
        break;

      case khaledTextMesh:
      case linkedInMesh:
      case linkedInBgMesh:
        window.open(linkedInUrl, "_blank").focus();
        break;

      case twitterMesh:
      case twitterBgMesh:
        window.open(twitterUrl, "_blank").focus();
        break;
    }
  }
});

/**
 * Camera
 */

// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  200
);

setCameraPosition(camera, sizes);

scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.dampingFactor = 0.01;

controls.minAzimuthAngle = -Math.PI;
controls.maxAzimuthAngle = Math.PI + Math.PI / 2;

setControlPorps(controls, sizes);

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */

// const clock = new THREE.Clock();

const tick = () => {
  // const elapsedTime = clock.getElapsedTime();

  // Raycast

  raycaster.setFromCamera(mouse, camera);

  const objectsToTest = [];

  if (khaledTextMesh) objectsToTest.push(khaledTextMesh);
  if (instagramMesh) objectsToTest.push(instagramMesh);
  if (instagramBgMesh) objectsToTest.push(instagramBgMesh);
  if (linkedInMesh) objectsToTest.push(linkedInMesh);
  if (linkedInBgMesh) objectsToTest.push(linkedInBgMesh);
  if (twitterMesh) objectsToTest.push(twitterMesh);
  if (twitterBgMesh) objectsToTest.push(twitterBgMesh);

  if (objectsToTest.length) {
    const intersects = raycaster.intersectObjects(objectsToTest);
    if (intersects.length) {
      currentIntersect = intersects[0];
      cursor.classList.add("active");
      cursor2.classList.add("active");
    } else {
      currentIntersect = null;
      if (isCursorActive) {
        cursor.classList.add("active");
        cursor2.classList.add("active");
      } else {
        cursor.classList.remove("active");
        cursor2.classList.remove("active");
      }
    }
  }

  cursor.style.cssText = cursor2.style.cssText =
    "left: " + client.x + "px; top: " + client.y + "px;";

  // Update controls
  controls.update();

  // console.log(camera.position);
  // console.log(controls.target);

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
